import { React } from "react";
import { useSelector } from "react-redux";
import { getFlightState, getMainCabin } from "../selectors/flights";
import { getDatas } from "../selectors/userSelection";
import {
  getFirstDestination,
  getDepartureCityLabel,
  getDestination,
} from "../selectors/destination";
import EditButton from "../components/EditButton";
import DeleteButton from "../components/DeleteButton";
import chevronLeft from "../img/icons/arrow-right-main-color.png";
import outbound from "../img/icons/outbound.svg";
import inbound from "../img/icons/inbound.svg";
import FlightSegmentRecap from "../components/FlightSegmentRecap";

const FlightRecapMode = ({ block }) => {
  const { flight } = useSelector(getFlightState);
  const {
    flight: {
      external: { code, cabin_aller, cabin_retour, departure_city },
    },
    next_dest,
  } = useSelector(getDatas);
  const flightInfo = flight["external"][code];

  const { label_destination } = useSelector(getFirstDestination);
  let sec_destination_label = "";

  sec_destination_label = useSelector(getDestination(next_dest))[
    "label_destination"
  ];

  const return_destination =
    sec_destination_label === undefined
      ? label_destination
      : sec_destination_label;
  const departure_city_label = useSelector(
    getDepartureCityLabel(departure_city)
  );

  const { aller, retour, inter, info } = flightInfo;
  return (
    <div className="bg-white p-5 rounded-md">
      <div className="flex justify-between items-center mb-5">
        <h3 className="gold-color text-fs-21 leading-lh-27 merriweather font-bold">
          Vols
        </h3>
        <div className="flex">
          <EditButton
            block={block}
            classNames={
              "main-color text-fs-14 border-b-main-color flex self-center border-b-1-5 border-accent mr-4"
            }
          />
          <DeleteButton block={block} />
        </div>
      </div>

      <div className="bg-grey-pagination rounded-md flex justify-between main-color mb-2.5 tablet:flex-col">
        <div className="desktop:w-1/2 desktop:border-r desktop:border-r-gray-200 tablet:border-b tablet:border-b-gray-200 flex py-14 pl-5">
          <div className="flex items-center">
            <img src={outbound} alt="outbound" />
            <span className="text-fs-14 ml-2.5 font-bold">Départ</span>
            <div className="bg-white rounded-md flex items-center h-value-30 px-5 ml-value-25">
              <span className="text-fs-14">{departure_city_label} </span>
              <img
                className="ml-5 w-1.5"
                src={chevronLeft}
                alt="chevron-left"
              />
              <span className="text-fs-14 ml-5">{label_destination}</span>
            </div>
            <div className="bg-white rounded-md flex items-center h-value-30 px-5 ml-4">
              <span className="text-fs-14">{getMainCabin(cabin_aller)}</span>
            </div>
          </div>
        </div>
        <div className="desktop:w-1/2 flex py-14 pl-5">
          <div className="flex items-center">
            <img src={inbound} alt="inbound" />
            <span className="text-fs-14 ml-2.5 font-bold">Retour</span>
            <div className="bg-white rounded-md flex items-center h-value-30 px-5 ml-value-25">
              <span className="text-fs-14">{return_destination}</span>
              <img
                className="ml-5 w-1.5"
                src={chevronLeft}
                alt="chevron-left"
              />
              <span className="text-fs-14 ml-5">{departure_city_label}</span>
            </div>
            <div className="bg-white rounded-md flex items-center h-value-30 px-5 ml-4">
              <span className="text-fs-14">{getMainCabin(cabin_retour)}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between">
        <div className="flex flex-col flex-1 rounded-md [&.selected]:shadow-selected">
          {/* VOL ALLER */}
          <FlightSegmentRecap segment={aller} info={info} type={"aller"} />

          {/* VOL INTER */}
          {inter !== undefined && (
            <FlightSegmentRecap segment={inter} info={info} type={"inter"} />
          )}

          {/* VOL RETOUR */}
          <FlightSegmentRecap segment={retour} info={info} type={"retour"} />
        </div>
        <div className="flex flex-col items-center justify-center w-value-100 ml-5">
          <span className="w-value-100 bg-accent h-26 flex items-center justify-center text-fs-13 rounded-md text-white font-semibold">
            Disponible
          </span>
        </div>
      </div>
    </div>
  );
};

export default FlightRecapMode;
