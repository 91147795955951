import { put } from "redux-saga/effects";
import { fetchWithTimeout } from "../helper";

function* fetchRealTime(action) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const { data, mode, flag } = action.payload;
  let {
    staydates: { startDate, endDate },
    dest,
    current_dest,
    hotels,
    flight,
    adults,
    children,
    transfert,
    rental,
    dates,
    assurance,
    is_multi_island,
    outboundDate,
    excursions,
    inboundDate,
    vendor,
    main_traveler,
    name_traveler,
    surname_traveler,
    email,
    vendor_code,
    next_dest,
    no_flight,
    api_key,
    special_request,
    ...datas
  } = data;

  const api_endpoint =
    mode === "quote"
      ? `${API_ENDPOINT}/api/v1/quote?dest=`
      : `${API_ENDPOINT}/api/v1/pricing?dest=`;

  const headers = new Headers();
  if (api_key.length > 0) headers.append("api-key", api_key);
  const multi_island = is_multi_island === "1" ? true : false;
  const multi_desti = current_dest !== dest ? true : false;
  let request =
    `${api_endpoint}` +
    dest +
    `&startDate=` +
    startDate +
    `&endDate=` +
    endDate +
    `&adults=` +
    adults +
    `&STA=OMU&multi_island=` +
    multi_island +
    "&multi_desti=" +
    multi_desti +
    "&outboundDate=" +
    outboundDate +
    "&inboundDate=" +
    inboundDate +
    "&special_request=" +
    special_request;
  if (vendor.length > 0) request += "&vendor=" + vendor;
  if (main_traveler.length > 0) request += "&main_traveler=" + main_traveler;
  if (vendor_code.length > 0) request += "&vendor_code=" + vendor_code;
  if (email.length > 0) request += "&email=" + email;
  if (name_traveler.length > 0) request += "&name_traveler=" + name_traveler;
  if (surname_traveler.length > 0)
    request += "&surname_traveler=" + surname_traveler;

  if (children !== undefined) {
    request += `&children=` + children;
    [...Array(parseInt(children))].forEach((_, i) => {
      request += `&age[${i + 1}]=` + datas[`age_${i + 1}`];
    });
  }

  if (multi_island === false) {
    for (const index in hotels) {
      for (const num in hotels[index]) {
        let desti = hotels[index][num]["dest"];
        let rooms = hotels[index][num]["rooms"];
        request +=
          `&hotels[${desti}][${num}][code]=${hotels[index][num]["code"]}` +
          `&hotels[${desti}][${num}][start_date]=${hotels[index][num]["start_date"]}` +
          `&hotels[${desti}][${num}][end_date]=${hotels[index][num]["end_date"]}` +
          `&hotels[${desti}][${num}][nbr_nights]=${hotels[index][num]["nbr_nights"]}` +
          `&hotels[${desti}][${num}][dest]=${desti}`;
        for (const num_rooms in rooms) {
          const ages = hotels[index][num]["rooms"][num_rooms]["ages"];
          request +=
            `&hotels[${desti}][${num}][rooms][${num_rooms}][code_room]=${hotels[index][num]["rooms"][num_rooms]["code_room"]}` +
            `&hotels[${desti}][${num}][rooms][${num_rooms}][status]=${hotels[index][num]["rooms"][num_rooms]["status"]}` +
            `&hotels[${desti}][${num}][rooms][${num_rooms}][adult_number]=${hotels[index][num]["rooms"][num_rooms]["adult_number"]}` +
            `&hotels[${desti}][${num}][rooms][${num_rooms}][children_number]=${hotels[index][num]["rooms"][num_rooms]["children_number"]}` +
            `&hotels[${desti}][${num}][rooms][${num_rooms}][formule][code]=${hotels[index][num]["rooms"][num_rooms]["formule_enhanced"]["code"]}` +
            `&hotels[${desti}][${num}][rooms][${num_rooms}][formule][lib]=${hotels[index][num]["rooms"][num_rooms]["formule_enhanced"]["lib"]}`;
          if (parseInt(ages) > 0) {
            let request_age = "";
            ages.forEach((_, i) => {
              request_age += `&hotels[${desti}][${num}][rooms][${num_rooms}][ages][${i}]=${hotels[index][num]["rooms"][num_rooms]["ages"][i]}`;
            });
            request += request_age;
          }
        }
      }
    }
  } else {
    var num_quote = 0;
    for (const index in hotels) {
      for (const num in hotels[index]) {
        let rooms = hotels[index][num]["rooms"];
        request +=
          `&hotels[${dest}][${num_quote}][code]=${hotels[index][num]["code"]}` +
          `&hotels[${dest}][${num_quote}][start_date]=${hotels[index][num]["start_date"]}` +
          `&hotels[${dest}][${num_quote}][end_date]=${hotels[index][num]["end_date"]}` +
          `&hotels[${dest}][${num_quote}][nbr_nights]=${hotels[index][num]["nbr_nights"]}` +
          `&hotels[${dest}][${num_quote}][island]=${hotels[index][num]["island"]}` +
          `&hotels[${dest}][${num_quote}][dest]=${hotels[index][num]["dest"]}`;
        for (const num_rooms in rooms) {
          const ages = hotels[index][num]["rooms"][num_rooms]["ages"];
          request +=
            `&hotels[${dest}][${num_quote}][rooms][${num_rooms}][code_room]=${hotels[index][num]["rooms"][num_rooms]["code_room"]}` +
            `&hotels[${dest}][${num_quote}][rooms][${num_rooms}][status]=${hotels[index][num]["rooms"][num_rooms]["status"]}` +
            `&hotels[${dest}][${num_quote}][rooms][${num_rooms}][adult_number]=${hotels[index][num]["rooms"][num_rooms]["adult_number"]}` +
            `&hotels[${dest}][${num_quote}][rooms][${num_rooms}][children_number]=${hotels[index][num]["rooms"][num_rooms]["children_number"]}` +
            `&hotels[${dest}][${num_quote}][rooms][${num_rooms}][formule][code]=${hotels[index][num]["rooms"][num_rooms]["formule_enhanced"]["code"]}` +
            `&hotels[${dest}][${num_quote}][rooms][${num_rooms}][formule][lib]=${hotels[index][num]["rooms"][num_rooms]["formule_enhanced"]["lib"]}`;
          if (parseInt(ages) > 0) {
            let request_age = "";
            let num_quote_r = num_quote;
            ages.forEach((_, i) => {
              request_age += `&hotels[${dest}][${num_quote_r}][rooms][${num_rooms}][ages][${i}]=${hotels[index][num]["rooms"][num_rooms]["ages"][i]}`;
            });
            request += request_age;
          }
        }
        num_quote++;
      }
    }
  }

  if (
    "external" in flight &&
    "code" in flight["external"] &&
    flag !== "no_flights"
  ) {
    const { cabin_aller, cabin_retour, code, departure_city, details } =
      flight["external"];
    const inter_dest_arrival =
      multi_desti === true ? next_dest : departure_city;
    const date_inter_dest =
      multi_desti === true ? dates[dest][0]["end_day"] : endDate;
    const trip = multi_desti === true ? "openjaw" : "AR";
    request += `&trip=` + trip;
    request += `&flight[code]=${code}`;
    request += `&pax[adt]=` + adults;
    if (children > 0) {
      let actual_children = 0;
      let babies = 0;
      [...Array(parseInt(children))].forEach((_, i) => {
        data[`age_${i + 1}`] < 2 ? babies++ : actual_children++;
      });
      if (actual_children > 0) {
        request += "&pax[chd]=" + actual_children;
      }
      if (babies > 0) {
        request += "&pax[inf]=" + babies;
      }
    }

    request +=
      "&segments[0][from]=" +
      departure_city +
      "&segments[0][date]=" +
      startDate +
      "&segments[0][cabin]=" +
      cabin_aller +
      "&segments[0][to]=" +
      dest;

    request +=
      "&segments[1][from]=" +
      dest +
      "&segments[1][to]=" +
      inter_dest_arrival +
      "&segments[1][date]=" +
      date_inter_dest +
      "&segments[1][cabin]=" +
      cabin_retour;

    if (multi_desti === true) {
      request +=
        "&segments[2][from]=" +
        next_dest +
        "&segments[2][to]=" +
        departure_city +
        "&segments[2][date]=" +
        endDate +
        "&segments[2][cabin]=" +
        cabin_retour;
    }
    for (const key in details) {
      request += `&flight_details[${key}][from]=${details[key]["from"]}`;
      request += `&flight_details[${key}][to]=${details[key]["to"]}`;
      for (const key_segment in details[key]["segments"]) {
        request += `&flight_details[${key}][${key_segment}][baggage]=${details[key]["segments"][key_segment]["baggage"]}`;
        request += `&flight_details[${key}][${key_segment}][cie]=${details[key]["segments"][key_segment]["cie"]}`;
        request += `&flight_details[${key}][${key_segment}][class]=${details[key]["segments"][key_segment]["class"]}`;
        request += `&flight_details[${key}][${key_segment}][datearr]=${details[key]["segments"][key_segment]["datearr"]}`;
        request += `&flight_details[${key}][${key_segment}][datedep]=${details[key]["segments"][key_segment]["datedep"]}`;
        request += `&flight_details[${key}][${key_segment}][duration]=${details[key]["segments"][key_segment]["duration"]}`;
        request += `&flight_details[${key}][${key_segment}][heurearr]=${details[key]["segments"][key_segment]["heurearr"]}`;
        request += `&flight_details[${key}][${key_segment}][heuredep]=${details[key]["segments"][key_segment]["heuredep"]}`;
        request += `&flight_details[${key}][${key_segment}][ville_arr]=${details[key]["segments"][key_segment]["ville_arr"]}`;
        request += `&flight_details[${key}][${key_segment}][ville_dep]=${details[key]["segments"][key_segment]["ville_dep"]}`;
      }
    }
  }
  if (typeof transfert === "object" && Object.keys(transfert).length > 0) {
    for (const dest in transfert) {
      request +=
        `&transfert[${dest}][code]=${transfert[dest]["code"]}` +
        `&transfert[${dest}][bl_linked_transfers]=${transfert[dest]["bl_linked_transfers"]}`;
    }
  }

  if (Object.keys(excursions).length > 0) {
    if (multi_island === false) {
      for (const dest in excursions) {
        for (const prestation_sub_code in excursions[dest]) {
          request +=
            `&excursions[${dest}][${prestation_sub_code}][date]=${excursions[dest][prestation_sub_code]["validity_day"]}` +
            `&excursions[${dest}][${prestation_sub_code}][prestation_code]=${excursions[dest][prestation_sub_code]["prestation_code"]}` +
            `&excursions[${dest}][${prestation_sub_code}][prestation_sub_code]=${excursions[dest][prestation_sub_code]["prestation_sub_code"]}` +
            `&excursions[${dest}][${prestation_sub_code}][supplier_code]=${excursions[dest][prestation_sub_code]["supplier_code"]}` +
            `&excursions[${dest}][${prestation_sub_code}][service_code]=${excursions[dest][prestation_sub_code]["service_code"]}`;
        }
      }
    } else {
      for (const desti in excursions) {
        for (const prestation_sub_code in excursions[desti]) {
          request +=
            `&excursions[${dest}][${prestation_sub_code}][date]=${excursions[desti][prestation_sub_code]["validity_day"]}` +
            `&excursions[${dest}][${prestation_sub_code}][prestation_code]=${excursions[desti][prestation_sub_code]["prestation_code"]}` +
            `&excursions[${dest}][${prestation_sub_code}][prestation_sub_code]=${excursions[desti][prestation_sub_code]["prestation_sub_code"]}` +
            `&excursions[${dest}][${prestation_sub_code}][supplier_code]=${excursions[desti][prestation_sub_code]["supplier_code"]}` +
            `&excursions[${dest}][${prestation_sub_code}][service_code]=${excursions[desti][prestation_sub_code]["service_code"]}`;
        }
      }
    }
  }

  if (typeof rental === "object" && Object.keys(rental).length > 0) {
    for (const dest in rental) {
      request +=
        `&rental[${dest}][subprovision_code]=${rental[dest]["subprovision_code"]}` +
        `&rental[${dest}][date_start]=${rental[dest]["date_start"]}` +
        `&rental[${dest}][date_end]=${rental[dest]["date_end"]}` +
        `&rental[${dest}][rental_code]=${rental[dest]["rental_code"]}`;
    }
  }

  if (assurance.length > 0 && assurance !== "none")
    request += `&insurance=${assurance}`;

  if (no_flight === true) request += `&no_flight=true`;

  try {
    const response = yield fetchWithTimeout(request, { headers });
    if (response.status === 200) {
      const json = yield response.json();
      yield put({ type: "real_time/putRealTime", json });
    } else {
      if (response.status === 204) {
        const message = response.headers.get("message");
        const x_cegi = response.headers.get("x-cegi");
        if (typeof message === "string" && message.length > 0)
          console.log("message", message);
        if (typeof x_cegi === "string" && x_cegi.length > 0)
          console.log("x-cegi", x_cegi);
      }
      throw response;
    }
  } catch (e) {
    yield put({ type: "real_time/errorRealTime", message: e.message });
  }
}

export { fetchRealTime };
