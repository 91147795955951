import { React } from "react";
import { useSelector } from "react-redux";
import { getDepartureCities } from "../selectors/destination";
import CancelButton from "../components/CancelButton";
import Select from "../components/Select";
import outbound from "../img/icons/outbound.svg";

const FlightStepOne = ({ onClick, block, index }) => {
  const departure_cities_options = useSelector(getDepartureCities);
  const { flight_type } = block;
  return (
    <div className="modal">
      <div className="modal-content max-w-1172">
        <div className="flex justify-between pb-5">
          <h3 className="gold-color text-fs-21 leading-lh-37 merriweather font-bold">
            Vols
          </h3>
        </div>

        <div className="flex flex-col main-color border-t border-t-gray-200 pt-5">
          <div className="flex mb-5">
            <img className="self-end mb-2.5" src={outbound} alt="outbound" />
            <div className="flex flex-col w-value-322 ml-value-30">
              <span className="leading-lh-18 text-fs-14 font-semibold mb-2.5">
                Ville de départ
              </span>
              <Select
                id={`flight.${flight_type}.departureCity`}
                options={departure_cities_options}
                default_value={"PAR"}
              />
            </div>
            <div className="flex flex-col w-value-322 ml-value-30">
              <span className="leading-lh-18 text-fs-14 font-semibold mb-2.5">
                Cabine aller
              </span>
              <Select
                name="Cabine aller"
                id={`flight.${flight_type}.cabin_aller`}
                options={[
                  { label: "Economique", value: "Y" },
                  { label: "Premium Éco", value: "S" },
                  { label: "Affaires", value: "C" },
                  { label: "Première", value: "F" },
                ]}
                default_value="Y"
              />
            </div>

            <div className="flex flex-col w-value-322 ml-value-30">
              <span className="leading-lh-18 text-fs-14 font-semibold mb-2.5">
                Cabine retour
              </span>
              <Select
                name="Cabine retour"
                id={`flight.${flight_type}.cabin_retour`}
                options={[
                  { label: "Economique", value: "Y" },
                  { label: "Premium Éco", value: "S" },
                  { label: "Affaires", value: "C" },
                  { label: "Première", value: "F" },
                ]}
                default_value="Y"
              />
            </div>
          </div>
          <div className="flex flex-col">
            <button
              type="button"
              className="p-16-35 leading-lh-15 block mx-auto main-bg-color text-white br-26 text-fs-14 w-value-226"
              onClick={() => onClick()}
            >
              Rechercher
            </button>
            <CancelButton block={block} index={index} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlightStepOne;
