import React from "react";
import { useFormContext } from "react-hook-form";

const ExcursionCheckBox = ({ id, name, dest, label, excursion_datas }) => {
  const { clearErrors } = useFormContext();
  const validate = (value, formValues) => {
    let valid = true;
    if (Array.isArray(value)) {
      (value || []).forEach((code) => {
        const { validity_day } = formValues["excursion"][dest][code];
        if (validity_day === "all" || !excursion_datas.includes(code)) {
          valid = false;
        }
      });
    }
    if (typeof value === "string" && value.length > 0) {
      const validity_day = formValues["excursion"][dest][value]["validity_day"];
      if (validity_day === "all" || !excursion_datas.includes(value)) {
        valid = false;
      }
    }
    return (
      valid ||
      `- Veuillez renseigner les dates des excursions sélectionnées de la destination ${label}`
    );
  };

  const { register } = useFormContext();
  return (
    <div className="customradiobutton relative cursor-pointer ml-5">
      <input
        {...register(name, {
          validate: validate,
        })}
        type="checkbox"
        id={id}
        value={id}
        /*      onChange={() => {
          // send data to react hook form
          clearErrors();
        }} */
      />
      <label className="checkmark" htmlFor={id}>
        Sélectionner
      </label>
    </div>
  );
};

export default ExcursionCheckBox;
