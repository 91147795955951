import { createSlice } from "@reduxjs/toolkit";

const realTimeSlice = createSlice({
  name: "real_time",
  initialState: {
    real_time: {},
    error: "",
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  reducers: {
    fetchRealTime: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = true;
      state.real_time = {};
    },
    putRealTime: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      const { cegi_debug, cegi_query, cegi_response, case_no } = action.json;
      if (cegi_debug !== undefined) {
        console.log("case_no", case_no);
        console.log("cegi_debug", cegi_debug);
      }
      if (cegi_query !== undefined) console.log("cegi_query", cegi_query);
      if (cegi_response !== undefined)
        console.log("cegi_response", cegi_response);
      state.real_time = action.json;
    },
    errorRealTime: (state) => {
      state.isLoading = false;
      state.isError = true;
    },
    resetRealTime: (state) => {
      state.real_time = {};
      state.error = "";
      state.isLoading = {};
      state.isSuccess = {};
      state.isError = {};
    },
  },
});

export const realTimeReducer = realTimeSlice.reducer;

export const { fetchRealTime, putRealTime, errorRealTime, resetRealTime } =
  realTimeSlice.actions;
