import { createSelector } from "reselect";

class Filter {
  constructor(key_filter, value, condition) {
    this.key_filter = key_filter;
    this.value = value;
    this.condition = condition;
  }
}

const RoomCodeExist = (state, code) => {
  return state.roomsReducer.rooms.hasOwnProperty(code);
};

const ErrorCodeExist = (state, code) => {
  return state.roomsReducer.errors.hasOwnProperty(code);
};

const isSuccess = (code) => (state) => {
  const success_obj = state.roomsReducer.isSuccess;
  if (code in success_obj) return success_obj[code];
  return false;
};

const isLoading = (code) => (state) => {
  const loading_obj = state.roomsReducer.isLoading;
  if (code in loading_obj) return loading_obj[code];
  return false;
};

const getRooms = createSelector(
  (rooms) => rooms,
  (_, count_adults) => count_adults,
  (_, __, count_children) => count_children,
  (_, __, ___, watched_ages) => watched_ages,
  (_, __, ___, ____, rooms_contents) => rooms_contents,
  (_, __, ___, ____, _____, room_selected) => room_selected,
  (
    rooms,
    count_adults,
    count_children,
    watched_ages,
    rooms_contents,
    room_selected
  ) => {
    const RoomsArray = Object.values(rooms);
    let filteredRooms = [];
    if (
      Object.keys(rooms).length > 0 &&
      (count_adults > 0 || count_children > 0)
    ) {
      const total_pax = count_adults + count_children;
      const sorted_ages = watched_ages.sort((a, b) => a - b);
      filteredRooms = Object.keys(rooms).filter((key) => {
        // initialiser les compte :
        let real_count_adults = count_adults;
        let real_count_children = 0;
        let real_count_babies = 0;
        let age_min = rooms[key]["age_min"];
        let age_max = rooms[key]["age_max"];
        let filters = [];
        let sorted_children = [];
        filters.push(new Filter("capa_maxi", total_pax, ">="));
        sorted_ages.forEach((age) => {
          if (age < age_min) real_count_babies++;
          if (age >= age_max) real_count_adults++;
          if (age_min <= age && age < age_max) {
            real_count_children++;
            sorted_children.push(age);
          }
        });

        if (real_count_adults > 0) {
          filters.push(new Filter("capamin_adulte", real_count_adults, "<="));
          filters.push(new Filter("capamax_adulte", real_count_adults, ">="));
        } else {
          if (real_count_children > 0 || real_count_babies > 0) {
            filters.push(new Filter("capamin_adulte", 0, "="));
          }
        }
        if (real_count_babies > 0) {
          filters.push(new Filter("capamax_bb", real_count_babies, ">="));
        }
        if (real_count_children > 0 && real_count_adults > 0) {
          filters.push(new Filter("capamin_enfant", real_count_children, "<="));
          filters.push(new Filter("capamax_enfant", real_count_children, ">="));
        }
        if (real_count_children > 1) {
          for (let i = 0; i < sorted_children.length; i++) {
            const age = sorted_children[i];
            if (i > 2) {
              break;
            }
            filters.push(new Filter(`age_max_enfant${i + 1}`, age, ">="));
          }
        }
        return filters.every((filter) => {
          const { key_filter, value, condition } = filter;
          const roomValue = rooms[key][key_filter];
          switch (condition) {
            case "=":
              return roomValue === value;
            case ">=":
              return roomValue >= value;
            case "<=":
              return roomValue <= value;
            default:
              return false;
          }
        });
      });
    }

    const filteredRoomsArray = RoomsArray.map((room) => {
      room = { ...room, hidden: true };
      if (room_selected.length > 0) {
        if (room["code"] === room_selected) room = { ...room, hidden: false };
      } else {
        if (filteredRooms.includes(room["code"]))
          room = { ...room, hidden: false };
      }
      return room;
    });

    // Convert the resulting array back to an object
    const filteredRoomsObject = Object.fromEntries(
      Object.keys(rooms).map((key, index) => [key, filteredRoomsArray[index]])
    );

    let contents_grouped = {};
    Object.keys(rooms_contents).forEach((key_content) => {
      let rooms_fares = {};
      let hidden = true;
      const room_content = rooms_contents[key_content];
      room_content["rooms"].forEach((key) => {
        if (key in filteredRoomsObject) {
          rooms_fares[key] = filteredRoomsObject[key];
          if (filteredRoomsObject[key]["hidden"] === false) hidden = false;
          delete filteredRoomsObject[key];
        }
      });
      if (Object.keys(rooms_fares).length > 0)
        contents_grouped[key_content] = {
          ...rooms_contents[key_content],
          rooms_fares,
          hidden,
        };
    });

    let hidden_ungrouped = true;
    Object.keys(filteredRoomsObject).forEach((key) => {
      if (filteredRoomsObject[key]["hidden"] === false)
        hidden_ungrouped = false;
    });

    if (Object.keys(filteredRoomsObject).length > 0) {
      const rooms_fares = { ...filteredRoomsObject };
      contents_grouped["ungrouped"] = {
        rooms_fares,
        hidden: hidden_ungrouped,
      };
    }

    let contents_sorted_array = Object.entries(contents_grouped);
    contents_sorted_array = contents_sorted_array.map((content) => {
      // Convert the rooms_fares object into an array of objects
      let roomsFaresArray = Object.entries(content["1"].rooms_fares);
      // Sort the array by the "prix" key
      roomsFaresArray.sort((a, b) => a[1].prix - b[1].prix);
      // Convert the sorted array back to an object
      content["1"]["rooms_fares_array"] = roomsFaresArray;
      return content;
    });

    contents_sorted_array.sort((a, b) => {
      const priceA =
        a[1].rooms_fares[getFirstKeyNotHiddenContent(a[1].rooms_fares)].prix;
      const priceB =
        b[1].rooms_fares[getFirstKeyNotHiddenContent(b[1].rooms_fares)].prix;
      return priceA - priceB;
    });

    return { filteredRooms, contents_sorted_array };
  }
);

const getFirstKeyNotHiddenContent = (rooms_fares) => {
  Object.keys(rooms_fares).forEach((key) => {
    if (rooms_fares[key]["hidden"] === false) {
      return key;
    }
  });
  return Object.keys(rooms_fares)[0];
};

const getContentBykey = createSelector(
  (key_room) => key_room,
  (_, content) => content,
  (key_room, content) => {
    let content_room = {};
    for (let key in content) {
      if (content[key]["rooms"].indexOf(key_room) !== -1) {
        content_room = { ...content[key] };
        break;
      }
    }
    return content_room;
  }
);

const formatRoomTitle = (str) => {
  let str_lowercase = str.toLowerCase();
  return (
    str_lowercase.charAt(0).toUpperCase() +
    str_lowercase.slice(1).replace(/\blux\b/gi, "LUX")
  );
};

const capitalize = (str) => {
  let str_lowercase = str.toLowerCase();
  return str_lowercase.charAt(0).toUpperCase() + str_lowercase.slice(1);
};

export {
  RoomCodeExist,
  ErrorCodeExist,
  getRooms,
  getContentBykey,
  isLoading,
  isSuccess,
  formatRoomTitle,
  capitalize,
};
